import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0069 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam('/column/details/C0002/')
  const routeParam2 = useRouteParam('/column/details/C0067/')
  const routeParam3 = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam4 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2023.11.16</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0069.jpg"
          alt="キンクラコラム画像69"
        />
        <p>
          <span>無料の勤怠管理システムがあることをご存じですか？</span>
          <br />
          これらのツールは、給与計算やシフトの管理、時間の記録など、企業における労働管理に関するさまざまな課題を解決するのに役立ちます。
          <br />
          本記事では、無料の勤怠管理システムのメリットや導入する際に気を付けたいことなどを解説していきます
        </p>
        <a href={routeParam} className="column-detail-link">
          参照記事：勤怠管理とは？管理をする必要性や管理体制の整備について解説
        </a>
        <br />
        <br />
        <h1 id="toc1">
          無料で勤怠管理を行うためにエクセルを活用する場合のデメリット
        </h1>
        <p>
          無料で勤怠管理を行うためにExcel(エクセル)で管理している企業も多くいるでしょう。
          <br />
          しかし、Excelで勤怠管理を行う場合、さまざまなデメリットが存在します。
        </p>
        <h2 id="toc1-1">工数がかかる</h2>
        <p>
          Excelでの勤怠管理は従業員の出勤時間、退勤時間、休憩時間などを手動で入力する必要があります。Excelでの勤怠管理は時間がかかるだけでなく、入力ミスの可能性もあります。また、月末になると、
          <span>
            各従業員の勤怠データを集計し、給与計算や労働時間管理も行わなければなりません。
          </span>
          集計作業は複雑であり、Excelだけでは効率的に行うことが難しい場合もあります。
        </p>
        <h2 id="toc1-2">多様な働き方に対応することが難しい</h2>
        <p>
          Excelの特性上、静的データ管理ツールでありシフト制や変形労働制、フレックスタイム制などさまざまな働き方に対応できる柔軟性はありません。働き方が多様であれば、従業員の勤務時間や勤務日が頻繁に変わるため、Excelで個々の状況に合わせて管理することは困難です。また、シフトの変更や時間の調整など、多くの作業を手動で行う必要があります。
          <span>
            多くの工程を手作業で行うことは時間がかかるだけでなく、ミスを誘発する恐れ
          </span>
          もあります。
        </p>
        <h2 id="toc1-3">不正入力のリスクがある</h2>
        <p>
          Excelでの管理は、代理での入力やデータの改ざんが容易なため、不正入力のリスクが発生します。もともとExcelを利用して従業員の勤務状況を一目で把握することは困難です。とくに、組織の規模が大きくなればなるほど、
          <span>従業員全員の勤怠状況を可視化することは難しくなる</span>
          でしょう。
          <br />
          <br />
          このように、Excelでの勤怠管理ではさまざまなリスクが存在します。
          <br />
          Excelを活用した勤怠管理のリスクを回避するためにはシステムの導入をおすすめします。
        </p>
        <a href={routeParam2} className="column-detail-link">
          参照記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <br />
        <br />
        <h1 id="toc2">無料の勤怠管理システムのメリットとは</h1>
        <img
          src="/images/column/details/c0069_g01.jpg"
          alt="キンクラコラム画像69"
        />
        <p>
          勤怠管理システムの中には、無料で導入することが可能なものも存在します。
          <br />
          本章では、無料の勤怠管理システムのメリットを解説していきます。
        </p>
        <a href={routeParam3} className="column-detail-link">
          参照記事：客先常駐に特化した勤怠管理システム「キンクラ」とは？
        </a>
        <br />
        <br />
        <h2 id="toc2-1">コスト削減が可能</h2>
        <p>
          無料の勤怠管理システムを導入することで、初期費用や月額費用を抑制できコスト削減につながります。無料の勤怠システムを導入することで、経営資源を他の重要な業務に投入することが可能になるでしょう。{' '}
        </p>
        <h2 id="toc2-2">お試し期間で他のシステムと比較できる</h2>
        <p>
          多くの無料の勤怠管理システムでは、一定期間の無料トライアルが提供されており、期間中に複数のシステムを試用できます。無料トライアルを利用することで、自社に必要な機能があるかを見つけだし、さらに複数のシステムを試すことでよりベストな選択ができるでしょう。
          <br />
          <br />
          以上のように、無料の勤怠管理システムにはさまざまなメリットがあります。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <h1 id="toc3">無料の勤怠管理システムを導入する際に気を付けたいこと</h1>
        <p>
          無料の勤怠管理システムはメリットだけではなく、デメリットも存在します。
        </p>
        <h2 id="toc3-1">人数制限がある</h2>
        <p>
          一部の無料の勤怠管理システムでは、
          <span>無料で利用できるのは一定の人数まで</span>
          という制限が設けられています。たとえば「10人以下までなら無料」といった形です。自社の従業員数が11人だった場合、人数制限により無料のシステムを使用できません。企業は追加の費用を支払うか、別のシステムを探す必要があります。また、企業規模が拡大し、従業員数が増加した場合も追加費用の対象です。企業によっては、想定外のコスト発生になり、予算計画に影響が出る場合もあります。{' '}
        </p>
        <h2 id="toc3-2">機能制限がある</h2>
        <p>
          無料で使える分、利用できる機能に制限があります。すべての機能を利用するためには、有料プランにアップグレードする必要があるでしょう。たとえば、特定の高度な機能（レポートの作成や、残業発生理由の分析など）は、有料プランでしか利用できない場合があります。企業が勤怠管理システムに高度な機能を求める場合、有料プランにアップグレードする必要があり、追加のコストが発生します。{' '}
        </p>
        <h2 id="toc3-3">期間制限がある</h2>
        <p>
          「1か月間だけ無料」など、無料期間が設けられている場合、
          <span>
            無料期間終了後は有料プランに移行するか、サービスを停止するかの選択が必要
          </span>
          です。サービスを利用し続けるためには、追加費用を支払う必要があり、想定外のコスト発生になります。また、無料期間が終了した後にサービスを停止すると、システムに依存していた業務も停止する可能性があるでしょう。サービスの停止は業務の遅延や生産性の低下を引き起こす可能性があります。
        </p>
        <h2 id="toc3-4">サポートに制限がある</h2>
        <p>
          <span>サポートに制限がある、もしくはまったくない場合もある</span>
          sため注意が必要です。問題が発生した際に迅速な対応を受けられない可能性があります。たとえば、システムの操作方法がわからない、エラーが発生した、特定の機能の使い方がわからない、などの問題が発生した場合、サポートが不十分だと解決までに時間がかかるか、解決しない可能性があります。問題が解決しない場合、業務の効率性や生産性に影響を与えるでしょう。サポートがまったく提供されていない場合は、自己解決するしかなく担当者にとっては大きなストレスにつながります。
          <br />
          <br />
          このように無料で使える分、デメリットもいくつか存在するため、導入を検討する際には注意が必要です。
        </p>
        <h1 id="toc4">
          無料で全機能が使えるクラウド型勤怠管理システム「キンクラ」がおすすめ
        </h1>
        <p>
          ここまで、Excelでの勤怠管理のデメリットや無料の勤怠管理システムのメリットなどを解説していきました。
          <br />
          もし、
          <span>
            無料の勤怠管理システムの導入を検討しているならば「キンクラ」をおすすめします。
          </span>
          <br />
          <br />
          20名以下ならば全機能を無料で使うことができます。
          <br />
          また、「キンクラ」は「客先常駐に特化」している勤怠管理システムのため、さまざまな働き方に対応したシステムです。
          <br />
          もし、勤怠管理システムの導入を検討しているならば「キンクラ」を選択することも一つの手です。
        </p>
        {/* TODO 石山：CTA内容が決まり次第差し替え */}
        <a href={routeParam4} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0069
